<template>
  <headGoBack :iCustomizdde="true">
    <template #default>
      <span>
        {{ $fanyi("定額会員") }}
      </span>
    </template>
  </headGoBack>
  <div class="additionalServiceFeePage">
    <div class="banner">
      <div class="bannerTop">「RAKUMART定額会員制度」</div>
      <div class="bannerBottom">
        <div class="leftText">
          定額会員になって、さらなる特典をお楽しみください
        </div>
        <!-- <div class="rightText">享受各种优惠</div> -->
      </div>
    </div>
    <div class="fgx"></div>
    <div class="sectionHeader">
      <div class="vipGradeListContainer">
        <div class="vipGradeContainer">
          <div class="imgBox">
            <img
              src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202307/64ba3a8eaea34.png"
              alt=""
              style="width: 0.48rem; height: 0.41333rem"
            />
          </div>

          <div>一般会員</div>
        </div>
        <div class="vipGradeContainer">
          <div class="imgBox">
            <img
              src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202307/64ba3aa499422.png"
              alt=""
              style="width: 0.49333rem; height: 0.34667rem"
            />
          </div>

          <div>定额会員</div>
        </div>
        <div class="vipGradeContainer" style="margin-right: 0">
          <div class="imgBox">
            <img
              src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202307/64ba3aace81c2.png"
              alt=""
              style="width: 0.45333rem; height: 0.42667rem"
            />
          </div>

          <div style="color: #7d5610">SVIP</div>
        </div>
      </div>
      <div class="servicesAvailable backgroundF6F6F6">
        <div class="servicesAvailableLabel">手数料</div>
        <div class="item">3% ~ 5%</div>
        <div class="item">０％</div>
        <div class="item">０％</div>
      </div>
      <div class="servicesAvailable">
        <div class="servicesAvailableLabel">商品調査</div>
        <div class="item">×</div>
        <div class="item">
          無料 <br />
          (回数制限あり)
        </div>
        <div class="item">
          無料 <br />
          (制限なし)
        </div>
      </div>
      <div class="servicesAvailable backgroundF6F6F6">
        <div class="servicesAvailableLabel">画像翻訳</div>
        <div class="item">なし</div>
        <div class="item">商品詳細ページの画像</div>
        <div class="item">商品詳細ページの画像</div>
      </div>
      <div class="servicesAvailable">
        <div class="servicesAvailableLabel">優先処理</div>
        <div class="item">なし</div>
        <div class="item">〇</div>
        <div class="item">〇</div>
      </div>
      <div class="servicesAvailable backgroundF6F6F6">
        <div class="servicesAvailableLabel">オプション費用</div>
        <div
          class="item cursorPointer colorB4272B"
          @click="pageJump"
          style="color: #b4272b"
        >
          詳細へ
        </div>
        <div
          class="item cursorPointer colorB4272B"
          @click="pageJump"
          style="color: #b4272b"
        >
          詳細へ
        </div>
        <div class="item">個別相談可能</div>
      </div>
      <div class="newServicesAvailable">
        <div>その他</div>
        <div class="flexAndCenter">
          <span
            >当社は会員様に対して多様なサービスや特別なカスタマイズサービスを提供しています，<br />詳細は《</span
          ><span class="cursorPointer colorB4272B" @click="pageJump"
            >オプション費用</span
          >

          <span>
            》をご覧ください。<br />
            記載されていない物について、担当者まで相談可能。</span
          >
        </div>
      </div>
    </div>
    <div class="fgx"></div>
    <div class="huijianList">
      <div
        class="huijianListItem"
        style="
          background: linear-gradient(-90deg, #f6f6f6, #ffffff);
          border-color: #dfdfdf;
        "
      >
        <div class="colorHead" style="background: #dfdfdf"></div>
        <div class="itemH1">一般会員</div>
        <div class="itemH2"><b>3%-5%</b> 手数料</div>
        <div class="itemCOn">登録後５％から</div>
      </div>
      <div
        class="huijianListItem"
        style="
          background: linear-gradient(-90deg, #fff5f0, #ffffff);
          border-color: #dfdfdf;
        "
      >
        <div
          class="colorHead"
          style="background: linear-gradient(-90deg, #e66055, #f18550)"
        ></div>
        <div class="itemH1">定额会員</div>
        <div class="itemCOn">
          ※月に50万円以上の商品を注文されるお客様におすすめです。
        </div>
      </div>
      <div
        class="huijianListItem"
        style="
          background: linear-gradient(-90deg, #f3dfbb, #ffffff);
          border-color: #dfdfdf;
        "
      >
        <div
          class="colorHead"
          style="background: linear-gradient(-90deg, #d9aa4d, #f1d8ac)"
        ></div>
        <div class="itemH1">SVIP</div>
        <div class="itemCOn">
          平均毎月一定額の仕入れ量を超えるお客様を個別招待させていただきます。
        </div>
      </div>
    </div>
    <div class="fgx"></div>
    <div class="ruleBox">
      <div class="ruleBoxHeader">{{ proxy.$fanyi("关于规则事项") }}</div>
      <div class="ruleEvent">
        <div class="ruleItem">
          1、{{
            $fanyi(
              "目前无论客户等级如何，会费支付完毕及确定后，以后的代理手续费一律为0%。"
            )
          }}
        </div>
        <div class="ruleItem">
          2、{{
            $fanyi(
              "申请成功后，从负责人通知确定的当天开始计算天数。收费会员期限不是按月计算，按天数计算。例如，1月有31天，从1/1日开始的话，有效期是1/30天，1/31就不适用了。"
            )
          }}
        </div>
        <div class="ruleItem">
          3、{{
            $fanyi("确定为收费会员后，关于未支付的订单，手续费将自动变为0%。")
          }}
        </div>
        <div class="ruleItem">
          4、{{
            $fanyi(
              "期间因中国的法定假日等原因连续休息5天以上的情况自动加上相应的天数。(例如:国庆节、春节等)"
            )
          }}
        </div>
        <div class="ruleItem">
          5、{{
            $fanyi(
              "收费会员失效前5天，系统会自动发送通知邮件，但由于某些原因延迟。因为也有无法到达的情况，关于失效期限，请自己管理。"
            )
          }}
        </div>
        <div class="ruleItem">
          6、{{
            $fanyi(
              "请注意，关于成为收费会员期间的变更、退款不能接受。因为我们事先预付了手续费(约定)，是可以产生优惠的服务。万一，在交易中发生了什么问题，由弊公司引起的原因的话，将会提出收费会员费返还以外的补偿方案。"
            )
          }}
        </div>
      </div>
    </div>
  </div>
  <footBar />
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import headGoBack from "@/components/headGoBack";
import footBar from "@/components/footBar/index.vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
// 会员数组
const pageJump = () => {
  location.href = "/option";
};
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.cursorPointer {
  cursor: pointer;
}
.colorB4272B {
  color: #b4272b;
}
.fgx {
  height: 30px;
  background-color: #f6f6f6;
}
.additionalServiceFeePage {
  background-color: white;
  .banner {
    width: 750px;
    height: 450px;
    padding: 102px 30px 99px 30px;
    background-image: url("../../../assets/foot-img/interExpress/chargeVip.jpg");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    .bannerTop {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 5px;
      color: #ffffff;
      text-align: center;
      margin-bottom: 60px;
    }
    .bannerBottom {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      letter-spacing: 15px;
      display: flex;
      justify-content: space-around;
      .leftText {
        line-height: 1.6;
        font-size: 24px;
      }
    }
  }
  .sectionHeader {
    border-radius: 10px;
    padding: 30px;
    background: #fff;

    .vipGradeListContainer {
      display: flex;

      .vipGradeContainer {
        flex: 0 0 170px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 20px;
        .imgBox {
          width: 40px;
          height: 40px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
          }
        }

        div {
          color: #333333;
          font-weight: 600;
          font-size: 24px;
        }
        &:first-child {
          margin-left: auto;
        }
      }
    }

    .servicesAvailable {
      width: 100%;
      min-height: 56px;
      padding: 10px 0;
      display: flex;
      align-items: center;

      .servicesAvailableLabel {
        flex: 1;
        text-align: center;
        line-height: 56px;
        color: #333333;
        font-weight: 600;
        font-size: 22px;
      }

      .item {
        flex: 0 0 170px;
        line-height: 1.2;
        text-align: center;
        color: #333333;
        font-size: 20px;
        padding: 0 15px;
      }

      .item:last-child {
        margin-right: 0;
      }
    }

    .newServicesAvailable {
      width: 100%;
      min-height: 130px;
      padding: 10px 0;
      display: flex;
      align-items: center;

      div:first-child {
        flex: 0 0 180px;
        text-align: center;
        color: #333333;
        font-weight: 600;
        font-size: 22px;
        margin-right: 60px;
      }

      div:last-child {
        color: #999999;
        word-break: break-all;
        * {
          line-height: 1.3;
          word-break: break-all;
          font-size: 20px;
        }
      }
    }

    .backgroundF6F6F6 {
      background: #f6f6f6;
    }
  }
  .huijianList {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    .huijianListItem {
      width: 220px;
      border: 1px solid;
      border-radius: 10px;
      height: 246px;
      overflow: hidden;
      .colorHead {
        height: 10px;
        margin-bottom: 30px;
      }
      .itemH1 {
        line-height: 36px;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
      }
      .itemH2 {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 20px;
        margin-bottom: 20px;
        b {
          font-size: 32px;
          font-weight: bold;
          margin-right: 10px;
        }
      }
      .itemCOn {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #999999;
        padding: 0 15px;
      }
    }
  }

  .ruleBox {
    padding: 30px;
    margin-bottom: 30px;
    .ruleBoxHeader {
      font-size: 28px;
      font-weight: 600;
      color: #333;
      margin-bottom: 17px;
    }
    .ruleEvent {
      .ruleItem {
        font-size: 24px;
        font-weight: 400;
        color: #333;
        line-height: 35px;
      }
    }
  }
}
</style>
